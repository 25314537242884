.projects-page {
  .callbackblk {
    margin-top: 3rem;
  }
  .conteiner.proj-container {

  }
  .conteiner.proj-container:last-child {
    margin-bottom: 3rem
  }
}

/* project-slider */
.project-slider {
  align-items: stretch;
  position: relative;
  .info-side {
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-right: 1.5rem;
    z-index: 10;
    background-color: $color-white;
    @include dflex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .info-list {
      margin: 1rem 0;
      .info-list__item {
        color: $color-second;
        font-size: 0.875rem;
        line-height: 1.125rem;
        margin-bottom: 1rem;
      }
    }
  }
  .slider-side {
    z-index: 5;
  }
  &::before {
    @include bf_aft_block;
    height: 100%;
    width: calc((100vw - 1200px) / 2);
    background: $color-white;
    z-index: 10;
    top: 0;
    left: calc((-100vw + 1200px) / 2);
    @include mq(1200) {
      display: none;
    }
  }
  /* scroll */
  .scroll-frame__row {
    .scroll-frame__item {
      max-width: 375px;
      width: 100%;
      @include mq(767) {
        max-width: calc(100vw - 30px);
      }
      .project-item__img {
        height: 450px;
        width: 100%;
        @include bgcent;
        padding: 1rem;
        @include mq(1200) {
          height: 360px;
        }
        @include mq(767) {
          height: 320px;
        }
      }
      .project-item__head {
        .itemUrl {
          font-size: 1.5rem;
          @include mq(1200) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  &.projectsall {
    .info-side {
      .block_head {
        margin-bottom: 4rem;
      }
    }
  }
  &.projectsVideos {
    .scroll-frame__row {
      .scroll-frame__item {
        width: 640px;
        max-width: none;
        @include mq(767) {
          width: calc(100vw - 30px);
        }
        .videoBlock {
          // padding: 0px;
          // height: 450px;
          // width: 100%;
          // position: relative;
        }
      }
    }
  }
  @include mq(992) {
    .info-side {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 1.5rem;
      .info-list {
        margin: 0.5rem 0;
        @include dflex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        .info-list__item {
          margin: 0.5rem 0;
          width: 50%;
        }
      }
    }
    &.projectsall {
      .info-side {
        .block_head {
          margin-bottom: 1rem;
        }
      }
    }
  }
  @include mq(767) {
    .info-side {
      .info-list {
        .info-list__item {
          width: 100%;
        }
      }
    }
    &.projectsall {
      .info-side {
        .block_head {
        }
      }
    }
  }
}

/* SINGLE PROJECT */
.singleproject-page {
  .page-header {
    padding-bottom: 1rem!important;
    .project-info-list {
      .info-list__item {
        color: $color-second;
        margin-bottom: 1rem;
        font-weight: 500;
      }
      .info-list__text {
        margin-top: 3rem;
        color: $color-second;
        font-size: 0.875rem;
        font-weight: 100;
      }
    }
  }
  .block360, .ytVideo {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @include mq(992) {
    .block360 iframe {
      height: 480px;
    }
  }
  @include mq(767) {
    .block360 iframe {
      height: 320px;
    }
  }
}
.project_content {
  p {
    font-weight: 100;
    margin: 15px 0;
  }
}
.imageList {
  @include dflex;
  align-items: stretch;
  justify-content: flex-start;
}
.fullImg, .halfImg {
  max-width: 100%;
  position: relative;
  margin: 15px;
  height: auto;
  text-align: center;
  // max-height: 90vh;
  width: auto;
}
.fullImg {
  width: calc(100% - 32px);
}
.halfImg {
  width: calc(50% - 32px);
  @include mq(767) {
    width: calc(100% - 32px);
  }
}
.proj-gallery {
  margin-bottom: 6rem!important;
  .proj-gallery__line {
    align-items: stretch;
  }
  .gal-item {
    flex: auto;
    padding: 15px;
    max-width: none;
    .gal-item__img {
      width: 100%;
      height: auto;
    }
    &.halfItem {
      width: 50%!important;
    }
    &.fullItem {
      width: 100%;
    }
  }
}
