/* VARS */
$color-main: #000000;
$color-second: #5f5f5f;
$color-black: #000000;
$color-white: #ffffff;
$color-lightgrey: #E2E2E2;
$color-midgrey: #acacac;
$color-darkgrey: #959595;
$font-main: "MuseoSans", sans-serif;
$small-sm: 324px;
$small: 580px;
$medium: 768px;
$large: 992px;
$large-xl: 1200px;

@mixin bf_aft_block {
  content: " ";
  position: absolute;
  z-index: 0;
  display: block;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@mixin bshad {
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
}
@mixin bgcent {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@mixin dflex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@mixin eclips($countecl: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: $countecl;
  -webkit-line-clamp: $countecl;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
// Media Query
@mixin mq($break){
    @if type-of($break) == 'number' {
      @media(max-width: $break + 'px') {
        @content;
      }
    }
    @else {
      @error "No value could be retrieved for '#{$break}'";
    }
}
/* MAIN */
html {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  font-size: 16px;
  background-color: white;
  @include mq(992) {
    font-size: 14px;
  }
}

body {
  font-family: $font-main;
  color: $color-black;
  line-height: 1.35;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  margin: 0;
  font-weight: 300;
  &::-webkit-scrollbar-track,
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($color-main, 0.3);
    background-color: $color-white;
    border-radius: 0;
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar,
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: $color-white;
  }
  &::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    border-radius: 0;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, $color-second), color-stop(0.72, $color-second), color-stop(0.86, $color-second));
  }
  .container {
    max-width: 1200px;
  }
  &.open, &.noScroll {
    overflow: hidden;
  }
}

b,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 500;
  margin: 0.5rem 0;
  line-height: 1.35;
}

h2 {
  font-size: 1.875rem;
  margin-bottom: 1.5rem;
  @media only screen and (max-width: $large-xl) {
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
  }
  @media only screen and (max-width: $medium) {
    font-size: 1.35rem;
  }
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1.2rem;
  @media only screen and (max-width: $large-xl) {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}

h4 {
  font-size: 1.25rem;
  @media only screen and (max-width: $large-xl) {
    font-size: 1rem;
  }
}

h5 {
  font-size: 0.875rem;
}
h6 {
  font-size: 0.75rem;
}

p {
  margin: 0;
}

ol,
ul {
  margin: 1rem 0;
}

main,
section {
  position: relative;
  z-index: 5;
}
/* CURSOR */
html,* {
  cursor: none;
	@include mq($large-xl) {
		cursor: auto;
	}
}

.cursor {
  position: fixed;
  top: 100%;
  left: 100%;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
  z-index: 999999;
  mix-blend-mode: difference;
  pointer-events: none;
  transition: transform 0.2s;
	@include mq($large-xl) {
		display: none;
	}
}
.cursor.hover {
  height: 24px;
  width: 24px;
}
.cursor.drag {
  height: 36px;
  width: 36px;
}
/* HEADER */
@import "header";
/*------------------------------------*\
    MAIN
\*------------------------------------*/
.content-area {
  min-height: 68vh;
}
.article-content {
  padding: 0 15px;
  overflow: hidden;
  display: block;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
  .article-body {
    position: relative;
    &>.container, &>.container-fluid {
      padding: 4rem 15px;
    }
    &>.container:first-child {
      padding-top: 6rem;
    }
    @include mq(1200) {
      &>.container, &>.container-fluid {
        padding: 3rem 15px;
      }
    }
    @include mq(992) {
      &>.container:first-child {
        padding-bottom: 1rem;
      }
    }
  }
}
.main_head {
  font-size: 2.8rem;
  line-height: 1.2;
  margin-top: 0;
  margin: 3rem 0;
  font-weight: 900;
  padding-top: 2rem;
  letter-spacing: 0.1em;
  @include mq(1200) {
    font-size: 2.4rem;
    margin: 1rem 0;
  }
  @include mq(992) {
    font-size: 2.1rem;
    padding-top: 1rem;
  }
  @include mq(767) {
    font-size: 1.8rem;
  }
}
.description {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 900;
  @include mq(1200) {
    font-size: 1.6rem;
  }
  @include mq(767) {
    font-size: 1.2rem;
  }
}
.block_head {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 0;
  font-weight: 900;
  letter-spacing: 0.1em;
  @include mq(1200) {
    font-size: 1.35rem;
  }
}
.sub_head {
  font-size: 1rem;
  font-weight: 400;
  margin: 1rem 0 2rem;
  color: $color-darkgrey;
}
.valigncenter {
  align-items: center;
}
.content-center {
  width: 100%;
  text-align: center;
}
.bgForInlineForm, .bgForCallBack {
  display: none;
}
.plyr {
  .plyr__controls {
    opacity: 0;
  }
  .plyr__control--overlaid {
    background-color: transparent;
    background-image: url(../img/icons/playbtn.png);
    height: 96px;
    width: 96px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    svg {
      opacity: 0;
    }
    &:hover {
      background-color: transparent;
      background-image: url(../img/icons/playbtn.png);
    }
    @include mq(767) {
      width: 48px;
      height: 48px;
    }
  }
  &:hover {
    .plyr__controls {
      opacity: 1;
    }
  }
}
// .clearfix:before, .clearfix:after {
//   content: " ";
//   display: table;
// }
.imageWrap {
  position: relative;
  overflow: hidden;
  img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
  @include mq(992)  {
    text-align: center;
  }
}
/*------------------------------------*\
    PAGES
\*------------------------------------*/
/* PAGE 404 */
@import "pages/404";
/* PAGE HOME */
@import "pages/home";
/* PAGE VIDEO */
@import "pages/video";
/* PAGE PROJECTS */
@import "pages/projects";
/* PAGE price */
@import "pages/price";
/* PAGE ABOUT US */
@import "pages/aboutus";
/* PAGE CONTACTS */
@import "pages/contacts";
/*------------------------------------*\
    OTHER
\*------------------------------------*/
/* NAV list */
.nav-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  @include dflex;
  align-items: flex-start;
  justify-content: flex-start;
  .nav-item {
    padding: 0.5rem;
    .nav-item__url {
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: 1px;
    }
  }
}
/* CONTACTS block */
.contacts-block {
  .contacts-block__line {
    margin-bottom: 1rem;
  }
  .line_head {
    color: $color-darkgrey;
    margin-bottom: 0.5rem;
  }
  .contacts-block__url, .contacts-block__text {
    font-size: 1.25rem;
  }
}
/* ANY SLIDER */
.any-slider.slick-initialized {
  .slick-slide.row {
    display: flex;
    margin: 0;
    outline-width: 0px;
  }
  .slick-list {
    z-index: 3;
  }
  .slick-arrow {
    position: absolute;
    top: 25vh;
    left: auto;
    z-index: 10;
    width: 40px;
    height: 17px;
    background-color: transparent!important;
    font-size: 0px;
    line-height: 0;
    color: transparent!important;
    @include bgcent;
    background-size: contain;
    margin-top: 5px;
    &.slick-prev {
      left: calc((100vw - 1145px) / 2);
      background-image: url(../img/icons/arr-left-white.png);
    }
    &.slick-next {
      left: calc((100vw - 860px) / 2);
      background-image: url(../img/icons/arr-right-white.png);
    }
    @include mq(1200) {
      &.slick-prev {
        left: 30px;
      }
      &.slick-next {
        left: 170px;
      }
    }
  }
  .slick-dots {
    margin: 0;
    list-style: none;
    position: relative;
    @include dflex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
    z-index: 5;
    height: 30px;
    margin-top: -5rem;
    margin-bottom: 0;
    li {
      padding-right: 2.5rem;
      button {
        font-size: 0px;
        border: none;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $color-darkgrey;
        overflow: hidden;
        padding: 0;
      }
      &.slick-active {
        button {
          background-color: $color-second;
          height: 15px;
          width: 15px;
        }
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
/* GOOGLE MAP */
// #map {
//   height: 360px;
// }
// .map-block {
//   #map {
//     height: 530px;
//   }
// }
/* SCROLL BLOCK */
.scroll-frame {
   width: 100%;
   height: auto;
   padding: 0;
   position: relative;
   z-index: 3;
   margin: 0!important;
   overflow: visible!important;
  .scroll-frame__row {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    // cursor: move;
    position: relative;
    .scroll-frame__item {
      float: left;
      margin: 0 5px 0 0;
      padding: 0;
      height: 100%;
      flex: none;
      margin-bottom: 1rem;
      margin-right: 2.5rem;
      position: relative;
      display: inline-block;
      .img-part {
        padding: 1rem;
        border-radius: 5px;
      }
    }
  }
}
.frame-scrollbar {
  width: 100%;
  height: 1px;
  background-color: $color-lightgrey;
  position: relative;
  z-index: 5;
  margin-bottom: 2.5rem;
  .handle {
  	width: 10px;
  	height: 3px;
    margin-top: -1px;
  	background: $color-second;
    position: absolute;
    .mousearea {
      position: absolute;
      top: -9px;
      left: 0;
      width: 100%;
      height: 20px;
      cursor: pointer;
      z-index: 7;
    }
  }
}
/* CONTACTS BLOCK */
.contacts-block {
  .contacts-block__url {
    margin-bottom: 0.5rem;
    display: block;
    position: relative;
    font-size: 1.5rem;
    font-weight: 900;
  }
}
/* CALL BACK BLOCK */
.callbackblk {
  min-height: 100vh;
  @include bgcent;
  background-image: url(../img/pages/callback_bg.jpg);
  &>.row {
    flex-direction: row;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-content: center;
  }
  @include mq(992) {
    min-height: 50vh;
  }
}
/* INLINE FORM BLOCK */
.inlineformblk {
  min-height: 710px;
  @include bgcent;
  background-image: url(../img/pages/inline-form-bg.jpg);
  color: $color-white;
  padding: 5rem 15px!important;
  .info-line {
    margin-top: 3rem;
    align-items: center;
  }
  .contacts-block {
    text-align: right;
    .contacts-block__url {
      color: $color-white;
      font-weight: 700;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  }
  .soc-list {
    justify-content: flex-end;
    .soc-item .soc-item__url .soc-item__img {
      path {
        fill: $color-white;
      }
    }
  }
  #main-form {
    input, textarea {
      color: $color-white;
      border-color: $color-white;
    }
    input::placeholder, textarea::placeholder {
      color: $color-white;
    }
    .btn.btn-main {
      margin-top: 0.5rem;
      color: $color-white;
      &::after {
        background-color: $color-white;
      }
      &:hover {
        color: $color-black;
      }
    }
  }
  @include mq(1200) {
    min-height: 100vh;
  }
  @include mq(992) {
    min-height: auto;
    padding: 5rem 15px 3rem!important;
    .info-line {
      margin-top: 1rem;
      .left-side {
        margin-bottom: 2rem;
      }
    }
  }
}
/* INSTAGRAM LINE */
.instalineblk {
  padding: 5rem 15px!important;
  .block_head {
    margin-bottom: 3rem;
  }
}
#insta-feed {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  .item {
    &:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    &:nth-child(4) {
      display: none;
    }
    .img-featured-container {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
    }
    .insta_url {
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      transition: 0.5s;
    }
  }
  @include mq(992) {
    grid-template-columns: 1fr 1fr;
    .item {
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
/* SIDE LINE */
.sideLine {
  padding: 15px;
  border-left: 1px solid $color-black;
  margin: 2rem 0;
  p {
    color: $color-second;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: 100;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 700;
  }
  h5 {
    font-weight: 500;
    color: $color-second;
    margin: 0;
    font-size: 0.875rem;
  }
  @include mq(992) {
    margin: 1rem 0;
  }
}
/* CUSTOM FONTS */
@import "fonts";
/* FOOTER */
@import "footer";
/* BUTTONS */
@import "buttons";
/* FORM */
@import "form";
/* ANIMATIONS */
@import "animation";
