.page404 {
  .article-body {
    .right-side {
      .block_head {
        font-weight: 900;
        font-size: 12rem;
        line-height: 1;
        margin-bottom: 0;
      }
    }
  }
}
