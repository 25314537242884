.article-content.homepage {
  .article-body {
    &>.container, &>.container-fluid {
      padding: 6rem 15px;
    }
    @include mq(1200) {
      &>.container, &>.container-fluid {
        padding: 3rem 15px;
      }
    }
  }
}
.home-slider-inner {
  padding: 0px!important;
  .row {
    position: relative;
  }
  .pagingInfo {
    position: absolute;
    top: 25vh;
    z-index: 10;
    color: $color-white;
    left: calc((100vw - 1030px) / 2);
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 1;
    letter-spacing: 0.1rem;
    @include dflex;
    strong {
      margin: 0;
      font-size: 1rem;
    }
    @include mq(1200) {
      left: 85px;
    }
  }
}
.home-slider {
  height: 100vh;
  z-index: 9;
  // .slick-slide
  .slide-item {
    height: 100vh;
    @include bgcent;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5rem 0 0;
    .main_head {
      color: $color-white;
      padding-top: 0;
      margin-top: 25vh;
    }
    @include mq(992) {
      padding: 5rem 15px 0;
    }
  }
}
.plashkaBlk {
  background-color: $color-white;
  position: absolute;
  right: 0;
  bottom: -1px;
  z-index: 15;
  width: calc((100vw - 540px) / 2);
  .plashkaBlk__info {
    padding: 1rem;
    max-width: 300px;
    .btn.btn-main {
      @include dflex;
      align-items: center;
      &::before {
        @include bf_aft_block;
        @include bgcent;
        background-image: url(../img/icons/yt.svg);
        position: relative;
        width: 24px;
        height: 20px;
        margin: 0.5rem 0;
        margin-right: 0.5rem;
        background-size: contain;
      }
      &::after {
        display: none;
      }
      &:hover {
        color: $color-black;
        opacity: 0.75;
      }
    }
    .plashkaBlk__txt {
      font-size: 0.875rem;
      font-weight: 100;
      color: $color-second;
    }
  }
  @include mq(1200) {
    width: 360px;
  }
  @include mq(767) {
    display: none;
  }
}

.accordlist {
  .accordlist__line {
    .ac-list-item {
      margin-bottom: 1rem;
      .btn.btn-main.accord-btn {
        @include dflex;
        align-items: center;
        padding: 5px 5px 5px 40px;
        text-align: left;
        &::before, &::after {
          @include bf_aft_block;
          background-color: $color-black;
        }
        &::before {
          width: 18px;
          height: 3px;
          top: 18px;
          left: 0px;
        }
        &::after {
          width: 3px;
          height: 18px;
          max-height: 18px;
          top: 10px;
          left: 7.5px;
        }
        &:hover {
          color: $color-black;
        }
        @include mq(1200) {
          &::before {
            top: 15px;
          }
          &::after {
            top: 8px;
          }
        }
      }
      .itembody {
        max-height: 0px;
        transition: 0.5s;
        overflow: hidden;
        .item_text {

        }
      }
      &.active {
        .btn.btn-main.accord-btn {
          &::after {
            max-height: 0px;
          }
        }
        .itembody {
          max-height: 200vh;
        }
      }
    }
  }
}
// VIDEO BG
.myBg {
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  // min-width: 100%;
  // min-height: 100%;
  // width: auto;
  // height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
  overflow: hidden;
  video {
    // position: fixed;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    opacity: 0.6;
    transition: 1s opacity;
  }
  // @media only screen and (max-width: $large) {
  //   height: 45vh;
  //   video {
  //     height: 100%;
  //   }
  // }
}

// BEFOR AFTER
#comparison {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 66.67%;

  .sldLabel {
    position: absolute;
    z-index: 1;
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-black;
    background-color: $color-white;
    padding: 12px 28px;
    bottom: 0;
    @include mq($medium) {
      padding: 6px 12px;
    }
  }

  .labelBefore {
    left: 0;
  }

  .labelAfter {
    right: 0;
    z-index: 1;
  }

  input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    background-color: transparent;
    width: calc(100% + 50px);
    z-index: 5;
    opacity: 0;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: transparent;
    border: 4px solid white;
    opacity: 0;
  }

  input[type=range]::-moz-range-track {
    -moz-appearance: none;
    height: 15px;
    width: 100%;
    background-color: transparent;
    position: relative;
    outline: none;
    opacity: 0;
  }
}

.fullContainer {
  position: absolute;
  background-size: cover;
  font-size: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
}

#divisor {
  background-size: cover;
  position: absolute;
  width: 50%;
  bottom: 0;
  height: 100%;
  z-index: 2;
  overflow: hidden;

  &::after,
  &::before {
    content: '';
    position: absolute;
    right: -2px;
    width: 4px;
    height: calc(50% - 25px);
    background: white;
    z-index: 3;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

#handle {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 3;
}
