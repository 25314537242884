.contacts-page {
  .contacts-info {
    padding-top: 1rem;
    .soc-list__txt {
      margin-top: 4rem;
    }
  }
  .contacts-bg-blk {
    padding: 0 0 50%!important;
    @include bgcent;
    background-image: url(../img/pages/cont_bg.jpg);
  }
}
