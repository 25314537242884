.opacityNone {
  opacity: 0!important;
}
// .animated {
//   animation-duration: 0.75s;
//   animation-fill-mode: both;
// }
//
// .animated.infinite {
//   animation-iteration-count: infinite;
// }
//
// .animated.delay-1s {
//   animation-delay: 1s;
// }
//
// .animated.delay-2s {
//   animation-delay: 2s;
// }
//
// .animated.delay-3s {
//   animation-delay: 3s;
// }
//
// .animated.delay-4s {
//   animation-delay: 4s;
// }
//
// .animated.delay-5s {
//   animation-delay: 5s;
// }
//
// .animated.fast {
//   animation-duration: 800ms;
// }
//
// .animated.faster {
//   animation-duration: 500ms;
// }
//
// .animated.slow {
//   animation-duration: 2s;
// }
//
// .animated.slower {
//   animation-duration: 3s;
// }
//
// @media (print), (prefers-reduced-motion: reduce) {
//   .animated {
//     animation-duration: 1ms !important;
//     transition-duration: 1ms !important;
//     animation-iteration-count: 1 !important;
//   }
// }
// @keyframes fadeInUp {
//   from {
//     opacity: 0;
//     transform: translate3d(0, 100%, 0);
//   }
//
//   to {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
//   }
// }
// .fadeInUp {
//   animation-name: fadeInUp;
// }
// @keyframes fadeInLeft {
//   from {
//     opacity: 0;
//     transform: translate3d(-100%, 0, 0);
//   }
//   to {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
//   }
// }
// .fadeInLeft {
//   animation-name: fadeInLeft;
// }
// @keyframes fadeInRight {
//   from {
//     opacity: 0;
//     transform: translate3d(100%, 0, 0);
//   }
//   to {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
//   }
// }
// .fadeInRight {
//   animation-name: fadeInRight;
// }
//
// @keyframes fadeInDown {
//   from {
//     opacity: 0;
//     transform: translate3d(0, -100%, 0);
//   }
//   to {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
//   }
// }
// .fadeInDown {
//   animation-name: fadeInDown;
// }

/* FOR IMAGES */
.imageWrap {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  img {
    z-index: 1;
  }
  &::after,
  &::before {
    @include bf_aft_block;
    z-index: 6;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: $large) {
      display: none;
    }
  }
  &::before {
    background-color: $color-black;
    z-index: 5;
  }
  &::after {
    background-color: $color-white;
  }
  &.visible {
    &::before {
      -webkit-animation: reveal 2s cubic-bezier(0.77, 0, 0.175, 1) both;
      animation: reveal 2s cubic-bezier(0.77, 0, 0.175, 1) both;
    }

    &::after {
      -webkit-animation: revealBack 2s cubic-bezier(0.77, 0, 0.175, 1) both;
      animation: revealBack 2s cubic-bezier(0.77, 0, 0.175, 1) both;
    }
  }
  &:hover {
    transform: scale(1.05);
  }
}
@-webkit-keyframes reveal {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes reveal {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-webkit-keyframes revealBack {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes revealBack {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
