input, textarea {
  font-family: $font-main;
  width: 100%;
  outline-width: 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $color-black;
  color: $color-black;
  padding: 12px 0;
  line-height: 1;
  font-size: 1rem;
  font-weight: 100;
  background-color: transparent;
  position: relative;
  box-sizing: border-box;
  resize: none;
}
input::placeholder, textarea::placeholder {
  color: $color-black;
}
button, input[type=submit] {
  outline-width: 0;
  border-radius: 0;
  color: $color-black;
  border: none;
  padding: 10px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  width: auto;
  font-size: 0.875rem;
  font-weight: 700;
  &:hover {
    background-color: $color-black;
    color: $color-white;
  }
}
/* CONTACT FORM */
.contacts-form {
  padding-bottom: 2rem;
  text-align: right;
  .btn {
    margin: 1rem 0;
  }
}
/* INLINE FORM */
.inline-form {
  position: relative;
  @include dflex;
  align-items: baseline;
  justify-content: flex-start;
  input {
    width: auto;
    margin-right: 1rem;
  }
}
/* popup form */
.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 99999;
  width: 100%;
  height: 100vh;
  max-height: 0px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  transition: 0.5s;
  .popupMain {
    width: 100%;
    max-width: 600px;
    position: relative;
    display: block;
    background-color: $color-white;
    box-shadow: 0 0 5px 0px rgba(0, 1, 1, 0.5);
    padding: 45px;
    z-index: 10;
    max-height: 80vh;
    overflow: auto;
    .block_head {
      letter-spacing: normal;
      margin-bottom: 3rem;
    }
    .popupBody {
      padding-bottom: 0;
    }
    .close {
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 100;
      position: absolute;
      top: 20px;
      right: 25px;
    }
  }
  &.showPopup {
    padding: 15px;
    max-height: 100vh;
  }
  &.popupCalc {
    .popupMain {
      min-height: 70vh;
    }
  }
}

/* CALC form */
#popupCalc {
  .popupMain {
    .block_head {
      letter-spacing: normal;
      margin-bottom: 1.5rem;
    }
    .itemLine {
      margin-bottom: 2rem;
      width: 100%;
    }
  }
}

.calcList {
  .listItem {
    display: none;
    min-height: calc(80vh - 164px);
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    &.active {
      display: flex;
    }
  }
  .inputsList {
    @include dflex;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + 20px);
    margin: 1rem -10px;
    label {
      padding: 5px 10px 5px 30px;
      width: 50%;
      @include dflex;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      margin: 5px 0;
      line-height: 1;
      &::before {
        @include bf_aft_block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid $color-black;
        left: 10px;
      }
      input[type=radio] {
        // visibility: hidden;
        // overflow: hidden;
        width: 1px;
        height: 1px;
        position: absolute;
        left: 0;
        &:checked::before {
          @include bf_aft_block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          box-sizing: border-box;
          left: 15px;
          background-color: $color-black;
          top: 5px;
        }
      }
      img {
        order: 2;
        margin-left: -20px;
        max-width: calc(100% + 25px);
        margin-top: 10px;
      }
    }
    &.styleObject {
      label {
        width: 25%;
        font-size: 14px;
        line-height: 16px;
        &:last-child {
          width: 100%;
        }
      }
    }
    &.contObject {
      width: 100%;
      margin: 1rem 0;
    }
  }
  @include mq(992) {
    min-height: calc(70vh - 164px);
    .inputsList {
      label {
        input[type=radio] {

        }
        img {

        }
      }
      &.styleObject {
        label {
          width: 50%;
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}
.calcNav {
  @include dflex;
  align-items: center;
  justify-content: space-between;
  .counterPart {
    width: 50%;
    padding-right: 15px;
  }
  .buttonPart {
    width: 50%;
    padding-left: 15px;
    text-align: right;
    .nextStep {

    }
    .sendButton {
      display: none;
    }
    &.lastStep {
      .nextStep {
        display: none;
      }
      .sendButton {
        display: inline-block;
      }
    }
  }
}
