.main-header {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  border: none;
  transition: 0.5s;
  .main-header__block {
    @include dflex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
  }
  .logo {
    padding-right: 1rem;
    .logo__url {
      display: block;
    }
    .logo__img {
      height: 50px;
      width: auto;
    }
  }
  .main-nav {
    @include dflex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    .nav-list {
      width: 100%;
      max-width: 830px;
      justify-content: space-between;
      .nav-item {
        padding: 0 0.5rem;
      }
    }
  }
  .navBtn {
    display: none;
    @include mq(992) {
      display: block;
    }
  }
  &.lightHeader {
    .logo {
      .logo__img {
        path {
          fill: $color-white;
        }
      }
    }
    .main-nav {
      .nav-list {
        .nav-item {
          .nav-item__url {
            color: $color-white;
          }
        }
      }
    }
  }
  &.fixhead {
    background-color: rgba(0,0,0,0.8);
    .logo {
      .logo__img {
        path {
          fill: $color-white;
        }
      }
    }
    .main-nav {
      .nav-list {
        .nav-item {
          .nav-item__url {
            color: $color-white;
          }
        }
      }
    }
  }
  @include mq(992) {
    background-color: rgba(0,0,0,0.8);
    border: none;
    .logo {
      .logo__img {
        height: 40px;
        path {
          fill: $color-white;
        }
      }
    }
    .main-nav {
      @include dflex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      padding: 80px 40px;
      background-color: $color-black;
      transition: 0.5s;
      transform: translateX(-100vw);
      .nav-list {
        list-style: none;
        padding-left: 0;
        @include dflex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center!important;
        height: 100%;
        width: 100%;
        margin: 0;
        .nav-item {
          text-align: right;
          padding: 1rem 0;
          .nav-item__url {
            font-size: 1.5rem;
            line-height: 1.25;
            color: $color-white;
            font-weight: 700;
          }
        }
      }
    }
    &.open {
      .main-nav {
        transform: translateX(0px);
      }
    }
  }
}
