@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/museosanscyrl100.eot');                                    
  src: url('../fonts/museosanscyrl100.eot?#iefix') format('embedded-opentype'),
       url('../fonts/museosanscyrl100.woff') format('woff'),
       url('../fonts/museosanscyrl100.ttf')  format('truetype'),
       url('../fonts/museosanscyrl100.svg#museosanscyrl100') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/museosanscyrl300.eot');
  src: url('../fonts/museosanscyrl300.eot?#iefix') format('embedded-opentype'),
       url('../fonts/museosanscyrl300.woff') format('woff'),
       url('../fonts/museosanscyrl300.ttf')  format('truetype'),
       url('../fonts/museosanscyrl300.svg#museosanscyrl300') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/museosanscyrl500.eot');
  src: url('../fonts/museosanscyrl500.eot?#iefix') format('embedded-opentype'),
       url('../fonts/museosanscyrl500.woff') format('woff'),
       url('../fonts/museosanscyrl500.ttf')  format('truetype'),
       url('../fonts/museosanscyrl500.svg#museosanscyrl500') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/museosanscyrl700.eot');
  src: url('../fonts/museosanscyrl700.eot?#iefix') format('embedded-opentype'),
       url('../fonts/museosanscyrl700.woff') format('woff'),
       url('../fonts/museosanscyrl700.ttf')  format('truetype'),
       url('../fonts/museosanscyrl700.svg#museosanscyrl700') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/museosanscyrl900.eot');
  src: url('../fonts/museosanscyrl900.eot?#iefix') format('embedded-opentype'),
       url('../fonts/museosanscyrl900.woff') format('woff'),
       url('../fonts/museosanscyrl900.ttf')  format('truetype'),
       url('../fonts/museosanscyrl900.svg#museosanscyrl900') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
