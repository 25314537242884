.price-page {

}
.pricelist {
  .pricelist__line {
    align-items: stretch;
    .info-item {
      .itembody {
        padding-right: 28%;
      }
      p {
        font-size: 0.875rem;
        font-weight: 100;
        color: $color-second;
      }
      .btn-main {
        margin-top: 2rem;
      }
    }
    .list-item {
      margin-bottom: 4rem;
      .itembody {
        height: 100%;
        @include dflex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem 2rem;
        border-left: 1px solid $color-black;
        .item_head {
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .item__list {
          list-style: none;
          margin-bottom: auto;
          padding-left: 1rem;
          .list__option {
            font-weight: 100;
            line-height: 1.8;
            position: relative;
            strong {
              font-weight: 500;
            }
            &::before {
              @include bf_aft_block;
              left: -1rem;
              content: "\002d";
              font-size: 1rem;
              line-height: 1.8;
            }
          }
        }
        .item__price {
          font-size: 1.25rem;
          font-weight: 700;
          margin-top: 2rem;
        }
        .primech {
          font-size: 0.75rem;
        }
      }
    }
  }
  @include mq(767) {
    .pricelist__line {
      .info-item {
        margin-bottom: 3rem;
        .itembody {
          padding-right: 15px;
        }
        .btn-main {
          margin-top: 1rem;
        }
      }
      .list-item {
        margin-bottom: 1rem;
        .itembody {
          padding: 0.5rem 0;
          border-left: none;
          .item_head {
            font-size: 1.1rem;
            margin: 0;
          }
          .item__list {
            padding-left: 1rem;
            .list__option {
              font-weight: 300;
              line-height: 1.5;
              strong {
                font-weight: 500;
              }
            }
          }
          .item__price {
            font-size: 1.1rem;
            font-weight: 700;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
