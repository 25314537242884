.video-page {

}
.video-list {
  .video-list__line {
    align-items: stretch;
  }
}
.video-item {
  position: relative;
  margin-bottom: 2rem;
  .item__main {
    margin-bottom: 1rem;
  }
  .item__name {
    font-size: 0.875rem;
    color: $color-second;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
  .item__info, .item__date {
    font-size: 0.875rem;
    font-weight: 100;
    margin-bottom: 1.5rem;
    color: $color-second;
  }
  .item__date {

  }
}
