.aboutus-page {
  .project-slider .info-side {

  }
}
.columnList {
  list-style: none;
  padding: 0;
  @include dflex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  li {
    display: block;
    position: relative;
    width: 33.33%;
    font-size: 1rem;
    line-height: 1.35;
    padding: 1rem 0;
    box-sizing: border-box;
    font-weight: 700;
  }
  @include mq(992) {
    li {
      width: 50%;
    }
  }
  @include mq(767) {
    li {
      width: 100%;
      padding: 0.5rem 0;
    }
  }
}
.offerBlock {
  .rightSide {
    .block_head {
      margin-top: 1rem;
    }
  }
  .offerItem {
    position: relative;
    margin: 2rem 0 4rem;
    letter-spacing: 0.1em;
    .itemNumb {
      font-weight: 300;
      font-size: 1rem;
    }
    .itemHead {
      font-weight: 700;
      font-size: 1rem;
      margin: 0.5rem 0 1rem;
    }
    .itemTxt {
      font-size: 1rem;
      color: $color-second;
    }
  }
  @include mq(992) {
    .offerItem {
      margin: 1rem 0 2rem;
    }
  }
}
.teamBlock {

}
.teamList {
  .teamItem {
    margin: 2rem 0;
    .itemImg {
      width: 100%;
      height: 390px;
      object-fit: cover;
      object-position: center;
    }
    .itemHead {
      font-weight: 700;
      font-size: 1rem;
      margin: 1rem 0 0.5rem;
    }
    .itemTxt {
      font-size: 1rem;
      color: $color-second;
    }
  }
  @include mq(1200) {
    .teamItem {
      .itemImg {
        height: 320px;
      }
    }
  }
  @include mq(992) {
    .teamItem {
      .itemImg {

      }
    }
  }
}
.aboutUsBlock, .offerBlock {
  @include mq(992) {
    .leftSide {
      order: 2;
    }
    .rightSide {
      order: 1;
      margin-bottom: 3rem;
    }
  }
}
