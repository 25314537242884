a,
button, .btn {
  position: relative;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1;
  font-family: $font-main;
  color: $color-black;
  outline-width: 0px!important;
  // cursor: pointer;
  transition: 0.5s;
  &:hover {
    opacity: 0.7;
  }
  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
    color: $color-black;
    outline-width: 0px!important;
  }
}

form button,
input {
  font-family: $font-main;
}

input[type=submit] {
  cursor: pointer;
}
/* buttons & style */
.btn {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  display: inline-block;
  text-align: center;
  min-width: 100px;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.085em;
  padding: 0;
  &.btn-main {
    color: $color-black;
    background-color: inherit;
    z-index: 1;
    &::after {
      @include bf_aft_block;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: $color-black;
      z-index: -1;
    }
    &:hover {
      opacity: 1;
      color: $color-white;
      &::after {
        height: 100%;
      }
    }
  }
  /* SECOND BTN */
  &.btn-second {
    padding: 1.5rem 4rem;
    color: $color-black;
    background-color: $color-white;
    border-radius: 3rem;
  }
  /* UNDERLINE BTN */
  &.btn-underline {
    padding: 0.2rem 0;
    color: $color-second;
    background-color: transparent;
    border-bottom: 1px dashed $color-second;
    min-width: auto;
    border-radius: 0;
  }
  @include mq(1200) {
    font-size: 1.1rem;
    text-align: left;
  }
}

/* SOC LIST */
.soc-list {
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  @include dflex;
  align-items: flex-start;
  justify-content: flex-start;
  .soc-item {
    padding: 0 5px;
    .soc-item__url {
      height: 36px;
      width: 36px;
      border: none;
      @include dflex;
      align-items: flex-end;
      justify-content: center;
      .soc-item__img {
        height: auto;
        width: 30px;
        path, circle {
          fill: $color-black;
        }
      }
      &:hover {
        opacity: 0.7;
      }
      &.yt {
        margin-top: 4px;
      }
    }
  }
  &.soc-list__txt {
    margin: 2rem 0;
    .soc-item {
      margin-right: 2rem;
      .soc-item__url {
        width: auto;
        height: auto;
        font-size: 1.25rem;
        text-decoration: underline;
        font-weight: 700;
        &.yt {
          margin-top: 0;
        }
      }
    }
  }
  &.soc-list__transp {
    position: absolute;
    bottom: 2rem;
    z-index: 15;
    flex-direction: column;
    margin: 0;
    left: calc((100vw - 1145px) / 2);
    .soc-item {
      margin-right: 0;
      margin-bottom: 0.5rem;
      .soc-item__url {
        &.yt {
          margin-bottom: -4px;
        }
        .soc-item__img {
          path {
            fill: $color-white;
          }
        }
      }
    }
    @include mq(1200) {
      left: 25px;
    }
  }
}

/* HUMBERGER */
#nav-icon {
  margin: 0px auto;
  @include dflex;
  min-width: 80px;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  height: 40px;
	padding: 0;
  position: relative;
  -webkit-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  padding-right: 40px;
  span {
    display: block;
    position: absolute;
    height: 100%;
    max-height: 40px;
    width: 3px;
    background-color: $color-white;
    opacity: 1;
    left: auto;
    top: 0;
    right: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    &:nth-of-type(1) {
      right: 37px;
    }
    &:nth-of-type(2) {
      right: 18px;
    }
    &:nth-of-type(3) {
      right: 0;
      background-color: $color-second;
    }
  }
  strong {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    text-transform: lowercase;
    position: relative;
    color: $color-white;
    margin: auto 30px;
  }
  &.open {
    span {
      &:nth-of-type(1) {
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        right: 18px;
      }
      &:nth-of-type(2) {
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
      }
      &:nth-of-type(3) {
        max-height: 0px;
      }
    }
  }
  &:hover {
    opacity: 1!important;
  }
}
