.main-footer {
  background-color: $color-white;
  padding: 5rem 15px 3.25rem;
  .main-footer__block {
    &>.row {
      align-items: flex-start;
    }
    .ft_head {
      font-size: 1.25rem;
      font-weight: 900;
      margin-bottom: 2rem;
      width: 100%;
    }
  }
  .footer-nav-block {
    .footer-nav {
      .nav-list {
        flex-direction: column;
        width: 100%;
        .nav-item {
          padding: 0 0 0.5rem;
          .nav-item__url {
            font-size: 1.25rem;
            line-height: 1;
          }
        }
      }
    }
  }
  .footer-contacts-block {
    .contacts-block {
      .contacts-block__url {
        font-size: 1.25rem;
        font-weight: 300;
      }
    }
  }
  .soc-list {
    width: 100%;
  }
  @include mq(1200) {
    padding: 3rem 15px;
  }
  @include mq(992) {
    padding: 2rem 15px 0;
    .main-footer__block {
      .col-12 {
        margin-bottom: 2rem;
        .ft_head {
          margin: 0 0 1rem;
        }
      }
    }
    .main-footer .footer-nav-block .footer-nav .nav-list .nav-item .nav-item__url {
      font-size: 1.1rem;
    }
  }
}
.fixedScrollList {
  position: fixed;
  @include dflex;
  align-items: center;
  justify-content: space-between;
  z-index: 899;
  padding: 25px 0;
  bottom: 0;
  width: 200px;
  right: calc((100vw - 1170px) / 2);
  transition: 0.5s;
  transform: translateX(50vw);
  .listItem {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &.showList {
    transform: translateX(0);
  }
  @include mq(1200) {
    right: 15px;
  }
  @include mq(992) {
    transform: translateX(0);
    width: 150px;
    padding: 15px 0;
    .listItem {
      width: 35px;
      height: 35px;
    }
  }
}
